import { createSlice } from "@reduxjs/toolkit";
import { IProduct } from "../types/product";
import helmetRed1 from "../../assets/shop-page/helmet/helmet_red_1.jpg";
import helmetRed2 from "../../assets/shop-page/helmet/helmet_red_2.jpg";
import helmetRed3 from "../../assets/shop-page/helmet/helmet_red_3.jpg";
import helmetBlue1 from "../../assets/shop-page/helmet/helmet_blue_1.jpg";
import helmetBlue2 from "../../assets/shop-page/helmet/helmet_blue_2.jpg";
import helmetBlue3 from "../../assets/shop-page/helmet/helmet_blue_3.jpg";
import vestRed1 from "../../assets/shop-page/vest/vest_red_1.jpg";
import vestRed2 from "../../assets/shop-page/vest/vest_red_2.jpg";
import vestRed3 from "../../assets/shop-page/vest/vest_red_3.jpg";
import vestBlue1 from "../../assets/shop-page/vest/vest_blue_1.jpg";
import vestBlue2 from "../../assets/shop-page/vest/vest_blue_2.jpg";
import vestBlue3 from "../../assets/shop-page/vest/vest_blue_3.jpg";
import legsRed1 from "../../assets/shop-page/legs/legs_red_1.jpg";
import legsRed2 from "../../assets/shop-page/legs/legs_red_2.jpg";
import legsBlue1 from "../../assets/shop-page/legs/legs_blue_1.jpg";
import legsBlue2 from "../../assets/shop-page/legs/legs_blue_2.jpg";
import armsRed1 from "../../assets/shop-page/arms/arms_red_1.jpg";
import armsRed2 from "../../assets/shop-page/arms/arms_red_2.jpg";
import armsBlue1 from "../../assets/shop-page/arms/arms_blue_1.jpg";
import armsBlue2 from "../../assets/shop-page/arms/arms_blue_2.jpg";
import glovesRed1 from "../../assets/shop-page/gloves/gloves_red_1.jpg";
import glovesRed2 from "../../assets/shop-page/gloves/gloves_red_2.jpg";
import glovesBlue1 from "../../assets/shop-page/gloves/gloves_blue_1.jpg";
import glovesBlue2 from "../../assets/shop-page/gloves/gloves_blue_2.jpg";
import helmetBoxRed1 from "../../assets/shop-page/helmet-box/helmet-box_red_1.jpg";
import helmetBoxRed2 from "../../assets/shop-page/helmet-box/helmet-box_red_2.jpg";
import helmetBoxRed3 from "../../assets/shop-page/helmet-box/helmet-box_red_3.jpg";
import helmetBoxRed4 from "../../assets/shop-page/helmet-box/helmet-box_red_4.jpg";
import helmetBoxBlue1 from "../../assets/shop-page/helmet-box/helmet-box_blue_1.jpg";
import helmetBoxBlue2 from "../../assets/shop-page/helmet-box/helmet-box_blue_2.jpg";
import helmetBoxBlue3 from "../../assets/shop-page/helmet-box/helmet-box_blue_3.jpg";
import helmetBoxBlue4 from "../../assets/shop-page/helmet-box/helmet-box_blue_4.jpg";
import helmetBoxBlack1 from "../../assets/shop-page/helmet-box/helmet-box_black_1.jpg";
import helmetBoxBlack2 from "../../assets/shop-page/helmet-box/helmet-box_black_2.jpg";
import helmetBoxBlack3 from "../../assets/shop-page/helmet-box/helmet-box_black_3.jpg";
import helmetBoxBlack4 from "../../assets/shop-page/helmet-box/helmet-box_black_4.jpg";
import helmetSkiRed1 from "../../assets/shop-page/helmet-ski/helmet-ski_red_1.jpg";
import helmetSkiRed2 from "../../assets/shop-page/helmet-ski/helmet-ski_red_2.jpg";
import helmetSkiRed3 from "../../assets/shop-page/helmet-ski/helmet-ski_red_3.jpg";
import helmetSkiRed4 from "../../assets/shop-page/helmet-ski/helmet-ski_red_4.jpg";
import helmetSkiBlue1 from "../../assets/shop-page/helmet-ski/helmet-ski_blue_1.jpg";
import helmetSkiBlue2 from "../../assets/shop-page/helmet-ski/helmet-ski_blue_2.jpg";
import helmetSkiBlue3 from "../../assets/shop-page/helmet-ski/helmet-ski_blue_3.jpg";
import helmetSkiBlue4 from "../../assets/shop-page/helmet-ski/helmet-ski_blue_4.jpg";
import helmetSkiBlack1 from "../../assets/shop-page/helmet-ski/helmet-ski_black_1.jpg";
import helmetSkiBlack2 from "../../assets/shop-page/helmet-ski/helmet-ski_black_2.jpg";
import helmetSkiBlack3 from "../../assets/shop-page/helmet-ski/helmet-ski_black_3.jpg";
import helmetSkiBlack4 from "../../assets/shop-page/helmet-ski/helmet-ski_black_4.jpg";
import helmetTraRed1 from "../../assets/shop-page/helmet-tra/helmet-tra_red_1.jpg";
import helmetTraRed2 from "../../assets/shop-page/helmet-tra/helmet-tra_red_2.jpg";
import helmetTraRed3 from "../../assets/shop-page/helmet-tra/helmet-tra_red_3.jpg";
import helmetTraRed4 from "../../assets/shop-page/helmet-tra/helmet-tra_red_4.jpg";
import helmetTraRed5 from "../../assets/shop-page/helmet-tra/helmet-tra_red_5.jpg";
import helmetTraBlue1 from "../../assets/shop-page/helmet-tra/helmet-tra_blue_1.jpg";
import helmetTraBlue2 from "../../assets/shop-page/helmet-tra/helmet-tra_blue_2.jpg";
import helmetTraBlue3 from "../../assets/shop-page/helmet-tra/helmet-tra_blue_3.jpg";
import helmetTraBlue4 from "../../assets/shop-page/helmet-tra/helmet-tra_blue_4.jpg";
import helmetTraBlack1 from "../../assets/shop-page/helmet-tra/helmet-tra_black_1.jpg";
import helmetTraBlack2 from "../../assets/shop-page/helmet-tra/helmet-tra_black_2.jpg";
import helmetTraBlack3 from "../../assets/shop-page/helmet-tra/helmet-tra_black_3.jpg";
import helmetTraBlack4 from "../../assets/shop-page/helmet-tra/helmet-tra_black_4.jpg";
import helmetTraBlack5 from "../../assets/shop-page/helmet-tra/helmet-tra_black_5.jpg";
import helmetTraBlackWhite1 from "../../assets/shop-page/helmet-tra/helmet-tra_black-white_1.jpg";
import helmetTraBlackWhite2 from "../../assets/shop-page/helmet-tra/helmet-tra_black-white_2.jpg";
import helmetTraBlackWhite3 from "../../assets/shop-page/helmet-tra/helmet-tra_black-white_3.jpg";
import helmetTraBlackWhite4 from "../../assets/shop-page/helmet-tra/helmet-tra_black-white_4.jpg";
import helmetTraWhite1 from "../../assets/shop-page/helmet-tra/helmet-tra_white_1.jpg";
import helmetTraWhite2 from "../../assets/shop-page/helmet-tra/helmet-tra_white_2.jpg";
import helmetTraWhite3 from "../../assets/shop-page/helmet-tra/helmet-tra_white_3.jpg";
import helmetTraWhiteBlack1 from "../../assets/shop-page/helmet-tra/helmet-tra_white-black_1.jpg";
import helmetTraWhiteBlack2 from "../../assets/shop-page/helmet-tra/helmet-tra_white-black_2.jpg";
import helmetTraWhiteBlack3 from "../../assets/shop-page/helmet-tra/helmet-tra_white-black_3.jpg";
import vestDefRed1 from "../../assets/shop-page/vest-def/vest_def_red_1.jpg";
import vestDefRed2 from "../../assets/shop-page/vest-def/vest_def_red_2.jpg";
import vestDefRed3 from "../../assets/shop-page/vest-def/vest_def_red_3.jpg";
import vestDefBlue1 from "../../assets/shop-page/vest-def/vest_def_blue_1.jpg";
import vestDefBlue2 from "../../assets/shop-page/vest-def/vest_def_blue_2.jpg";
import vestDefBlue3 from "../../assets/shop-page/vest-def/vest_def_blue_3.jpg";
import vestDefBlack1 from "../../assets/shop-page/vest-def/vest_def_black_1.jpg";
import vestDefBlack2 from "../../assets/shop-page/vest-def/vest_def_black_2.jpg";
import vestDefBlack3 from "../../assets/shop-page/vest-def/vest_def_black_3.jpg";
import glovesBegRed1 from "../../assets/shop-page/gloves-beg/gloves-beg_red_1.jpg";
import glovesBegRed2 from "../../assets/shop-page/gloves-beg/gloves-beg_red_2.jpg";
import glovesBegBlue1 from "../../assets/shop-page/gloves-beg/gloves-beg_blue_1.jpg";
import glovesBegBlue2 from "../../assets/shop-page/gloves-beg/gloves-beg_blue_2.jpg";
import glovesBegBlack1 from "../../assets/shop-page/gloves-beg/gloves-beg_black_1.jpg";
import glovesBegBlack2 from "../../assets/shop-page/gloves-beg/gloves-beg_black_2.jpg";
import legsDefRed1 from "../../assets/shop-page/legs-def/legs-def_red_1.jpg";
import legsDefRed2 from "../../assets/shop-page/legs-def/legs-def_red_2.jpg";
import legsDefBlue1 from "../../assets/shop-page/legs-def/legs-def_blue_1.jpg";
import legsDefBlack1 from "../../assets/shop-page/legs-def/legs-def_black_1.jpg";
import legsMpBlack1 from "../../assets/shop-page/legs-mp/legs-mp_black-white_1.jpg";
import legsMtRed1 from "../../assets/shop-page/legs-mt/legs-mt_red_1.jpg";
import legsMtRed2 from "../../assets/shop-page/legs-mt/legs-mt_red_2.jpg";
import legsMtRed3 from "../../assets/shop-page/legs-mt/legs-mt_red_3.jpg";
import legsMtBlue1 from "../../assets/shop-page/legs-mt/legs-mt_blue_1.jpg";
import legsMtBlue2 from "../../assets/shop-page/legs-mt/legs-mt_blue_2.jpg";
import legsMtBlue3 from "../../assets/shop-page/legs-mt/legs-mt_blue_3.jpg";
import legsMtBlue4 from "../../assets/shop-page/legs-mt/legs-mt_blue_4.jpg";
import legsMtBlack1 from "../../assets/shop-page/legs-mt/legs-mt_black_1.jpg";
import legsMtBlack2 from "../../assets/shop-page/legs-mt/legs-mt_black_2.jpg";
import legsMtBlack3 from "../../assets/shop-page/legs-mt/legs-mt_black_3.jpg";
import legsMtBlack4 from "../../assets/shop-page/legs-mt/legs-mt_black_4.jpg";
import legsMtGold1 from "../../assets/shop-page/legs-mt/legs-mt_gold_1.jpg";
import legsMtGold2 from "../../assets/shop-page/legs-mt/legs-mt_gold_2.jpg";
import legsMtGold3 from "../../assets/shop-page/legs-mt/legs-mt_gold_3.jpg";
import legsMtGold4 from "../../assets/shop-page/legs-mt/legs-mt_gold_4.jpg";
import legsMtWhite1 from "../../assets/shop-page/legs-mt/legs-mt_white_1.jpg";
import legsMtWhite2 from "../../assets/shop-page/legs-mt/legs-mt_white_2.jpg";
import legsMtWhite3 from "../../assets/shop-page/legs-mt/legs-mt_white_3.jpg";

interface IProductsState {
  products: IProduct[];
}

const def = {
  id: 0,
  name: "",
  description: "",
  price: 0,
  colors: [{ name: "", key: "" }],
  sizes: [],
  images: {},
};

const initialState: IProductsState = {
  products: [
    {
      id: 0,
      name: "Шлем боксерский СФТБМР",
      description:
        "Шлем турнирный для тайского бокса Разработан при содействии федерации тайского бокса России (ФТБР). Конструкция шлема обеспечивает отличную обзорность. Верхняя часть головы защищена от ударов локтями накладкой, толщиной 120мм. Дугообразная форма лобной части шлема надежно прикрывает брови от рассечений. В подбородочной зоне имеются подушки, предназначенные для защиты от натирания подбородочным ремнем. Внутренний вкладыш имеет два слоя полимерной пены, что обеспечивает хорошую защиту головы спортсмена. Общая толщина шлема 280мм, в то же время он достаточно легкий, не более 350гр. Шлем имеет большой диапазон регулировок по размеру, благодаря широкой застежке на затылке. Два типоразмера позволяют охватить максимальный размерный ряд. M – от 52 до 56, L – от 56 до 60",
      price: 4800,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
      ],
      sizes: ["M", "L"],
      weights: [0.38, 0.4],
      images: {
        "#ff0000": [helmetRed1, helmetRed2, helmetRed3],
        "#1e90ff": [helmetBlue1, helmetBlue2, helmetBlue3],
      },
    },
    {
      id: 1,
      name: "Жилет защитный СФТБМР",
      description:
        "Жилет защитный для тайского бокса Модель спроэктирована и разработана при активном участии федерации тайского бокса России. Жилет изготовлен из износостойкой искусственной кожи. Подкладка из синтетической ткани, которая хорошо впитывает влагу и быстро сохнет, что продлевает срок службы жилета. Внутренний вкладыш жилета выполнен из полимерной пены и сконструирован таким образом, чтобы энергия удара максимально поглощалась и рассеивалась. В конструкции жилета, так же, предусмотрены дополнительные накладки для защиты области почек. На спине жилет крепится при помощи застежек-липучек. Идеальную посадку обеспечивают завязки в сочетании с эластичной тесьмой расположенные в верхней части жилета. Жилет представлен в четырех размерах: S, M, L, XL",
      price: 7200,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
      ],
      sizes: ["S", "M", "L"],
      weights: [0.6, 0.8, 1],
      images: {
        "#ff0000": [vestRed1, vestRed2, vestRed3],
        "#1e90ff": [vestBlue1, vestBlue2, vestBlue3],
      },
    },
    {
      id: 2,
      name: "Перчатки боксерские СФТБМР",
      description:
        "Перчатки боксерские для тайского бокса В разработке этой модели принимала активное участие федерация тайского бокса России (ФТБР). Перчатки прошли длительные тестовые испытания. Верх перчаток выполнен из износостойкой искусственной кожи, подкладка – из синтетической высокоэластичной ткани, которая очень быстро высыхает после использования. Вкладыши перчаток изготовлены из четырех слоев полимерной пены различной жесткости и плотности. В сочетании с бинтами это обеспечивает максимальную защиту кистей рук спортсмена от травм во время поединка. Большой палец прикреплен к основной части перчатки прочной тесьмой и прикрыт выступом. Перчатки крепятся на руке при помощи застежек на липучках.",
      price: 4800,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
      ],
      sizes: ["10 OZ"],
      images: {
        "#ff0000": [glovesRed1, glovesRed2],
        "#1e90ff": [glovesBlue1, glovesBlue2],
      },
    },
    {
      id: 3,
      name: "Защита локтя СФТБМР",
      description:
        "Защищает локтевой сустав при нанесении ударов локтями. Конструкция позволяет комфортно сгибать руку",
      price: 1050,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
      ],
      // sizes: ["S", "M", "L", "XL"],
      // weights: [0.25, 0.26, 0.27, 0.3],
      sizes: ["S", "M", "L"],
      weights: [0.25, 0.26, 0.27],
      images: {
        "#ff0000": [armsRed1, armsRed2],
        "#1e90ff": [armsBlue1, armsBlue2],
      },
    },
    {
      id: 4,
      name: "Защита голеностопа СФТБМР",
      description:
        "Cделана из эластана. Спереди защищающий слой из пенополиуретана. На ноге фиксируется резинкой на липучке и полностью облегает ногу.",
      price: 1300,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
      ],
      // sizes: ["S", "M", "L", "XL"],
      // weights: [0.28, 0.31, 0.34, 0.34],
      sizes: ["S", "M", "L"],
      weights: [0.28, 0.31, 0.34],
      images: {
        "#ff0000": [legsRed1, legsRed2],
        "#1e90ff": [legsBlue1, legsBlue2],
      },
    },
    {
      id: 5,
      name: "Шлем боксерский",
      description:
        "Шлем боксерский предназначен для защиты головы спортсменов от травм при ударах. Поверхность шлема выполнена из прочного износостойкого материала – высококачественной искусственной кожи. Многослойный вкладыш из пенополиуретана и пенополиэтилена различных плотностей амортизирует удары и хорошо сохраняет форму шлема. Дополнительную защиту от возможных ударов сверху обеспечивает плотная накладка на макушке. Конструкция шлема обеспечивает хорошую обзорность.",
      price: 3400,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
      ],
      sizes: ["M", "L"],
      weights: [0.4, 0.42],
      images: {
        "#ff0000": [helmetBoxRed1, helmetBoxRed2, helmetBoxRed3, helmetBoxRed4],
        "#1e90ff": [
          helmetBoxBlue1,
          helmetBoxBlue2,
          helmetBoxBlue3,
          helmetBoxBlue4,
        ],
        "#000000": [
          helmetBoxBlack1,
          helmetBoxBlack2,
          helmetBoxBlack3,
          helmetBoxBlack4,
        ],
      },
    },
    {
      id: 6,
      name: "Шлем тренировочный",
      description:
        "Тренировочный шлем рекомендуют использовать спортсменам во время спаррингов. По сравнению с моделями для соревнований, его конструкция предусматривает дополнительно защищенные подбородок, скуловые кости, макушку. Шлем имеет хорошую обзорность. Изготовлен из прочной высококачественной искусственной кожи. Внутренний наполнитель многослойный вкладыш из пенополиуретана и пенополиэтилена различных плотностей выполняет функцию амортизатора и хорошо держит форму. После первых тренировок шлем немного растягивается и принимает оптимальную для головы форму. Каждый размер шлема приспособлен к отдельеной возрастной группе: S - детский (объем головы до 53 см) М - юниоры (53-57 см) L - взрослый (57-60 см).",
      price: 4000,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
        { name: "черно-белый", key: "#000000-#ffffff" },
        { name: "белый", key: "#ffffff" },
        { name: "бело-черный", key: "#ffffff-#000000" },
      ],
      sizes: ["M", "L", "XL"],
      weights: [0.4, 0.44, 0.47],
      images: {
        "#ff0000": [
          helmetTraRed1,
          helmetTraRed2,
          helmetTraRed3,
          helmetTraRed4,
          helmetTraRed5,
        ],
        "#1e90ff": [
          helmetTraBlue1,
          helmetTraBlue2,
          helmetTraBlue3,
          helmetTraBlue4,
        ],
        "#000000": [
          helmetTraBlack1,
          helmetTraBlack2,
          helmetTraBlack3,
          helmetTraBlack4,
          helmetTraBlack5,
        ],
        "#000000-#ffffff": [
          helmetTraBlackWhite1,
          helmetTraBlackWhite2,
          helmetTraBlackWhite3,
          helmetTraBlackWhite4,
        ],
        "#ffffff": [helmetTraWhite1, helmetTraWhite2, helmetTraWhite3],
        "#ffffff-#000000": [
          helmetTraWhiteBlack1,
          helmetTraWhiteBlack2,
          helmetTraWhiteBlack3,
        ],
      },
    },
    {
      id: 7,
      name: "Шлем боксерский кожа",
      description:
        "Шлем боксерский предназначен для защиты головы спортсменов от травм при ударах. Поверхность шлема выполнена из прочного износостойкого материала – высококачественной кожи. Многослойный вкладыш из пенополиуретана и пенополиэтилена различных плотностей амортизирует удары и хорошо сохраняет форму шлема. Дополнительную защиту от возможных ударов сверху обеспечивает плотная накладка на макушке. Конструкция шлема обеспечивает хорошую обзорность.",
      price: 5300,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
      ],
      sizes: ["M", "L"],
      weights: [0.37, 0.4],
      images: {
        "#ff0000": [helmetSkiRed1, helmetSkiRed2, helmetSkiRed3, helmetSkiRed4],
        "#1e90ff": [
          helmetSkiBlue1,
          helmetSkiBlue2,
          helmetSkiBlue3,
          helmetSkiBlue4,
        ],
        "#000000": [
          helmetSkiBlack1,
          helmetSkiBlack2,
          helmetSkiBlack3,
          helmetSkiBlack4,
        ],
      },
    },
    {
      id: 8,
      name: "Жилет защитный",
      description:
        "Жилет – неотъемлемый элемент экипировки спортсмена для защиты корпуса. Предназначен для поглощения энергии ударов соперника по корпусу. Внешнее покрытие жилета изготовлено из прочного износостойкого материала – высококачественной искусственной кожи. Внутренняя часть выполнена из пенополиэтилена высокой плотности. Это надежная защита грудной клетки, брюшной полости, боков и спины. Комфортная и легкая конструкция жилета не стесняет движения. Фиксируется и регулируется с помощью ременной тесьмы.",
      // price: 2900,
      price: [2900, 3100, 3300],
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
      ],
      // sizes: ["S", "M", "L", "XL"],
      sizes: ["S", "M", "L"],
      // weights: [0.35, 0.37, 0.47, 0.52],
      weights: [0.35, 0.37, 0.47],
      images: {
        "#ff0000": [vestDefRed1, vestDefRed2, vestDefRed3],
        "#1e90ff": [vestDefBlue1, vestDefBlue2, vestDefBlue3],
        "#000000": [vestDefBlack1, vestDefBlack2, vestDefBlack3],
      },
    },
    {
      id: 9,
      name: "Перчатки боксерские Beginning",
      description:
        "Перчатки с многослойным вкладышем из пенополиуретана и пенополиэтилена различных плотностей максимально снижает риск получения травмы кисти. Застежка-липучка велкро позволяет легко надевать и снимать перчатки самостоятельно, при этом отлично фиксирует запястье. У перчаток удобный хват, они надежно защищают руки бойца.",
      price: [3400, 3500],
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
      ],
      // sizes: ["8 OZ", "10 OZ", "12 OZ"],
      // weights: [0.5, 0.56, 0.68],
      sizes: ["8 OZ", "10 OZ"],
      weights: [0.5, 0.56],
      images: {
        "#ff0000": [glovesBegRed1, glovesBegRed2],
        "#1e90ff": [glovesBegBlue1, glovesBegBlue2],
        "#000000": [glovesBegBlack1, glovesBegBlack2],
      },
    },
    {
      id: 10,
      name: "Защита голеностопа",
      description:
        "Защита голеностопа используется спортсменами во время спаррингов или тренировок в тех контактных единоборствах, где разрешены удары ногами. Это комплексное решение для защиты голени и ступни. Позволяет минимизировать риск получения травмы, смягчить удары. Защита голеностопа изготовлена из высококачественной искусственной кожи, внутренний наполнитель – пенополиуретан. Надежно фиксируется на ноге с помощью эластичной текстильной ленты и застежки-липучки велкро. Нескользящая подкладка позволяет бойцу чувствовать себя комфортно и уверенно работать в полную силу. Дополнительную защиту обеспечивает специальное утолщение в районе большеберцовой кости и накладка на подъем стопы.",
      price: 3900,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
      ],
      // sizes: ["S", "M", "L", "XL"],
      sizes: ["M", "L", "XL"],
      // weights: [0.45, 0.52, 0.58, 0.65],
      weights: [0.52, 0.58, 0.65],
      images: {
        "#ff0000": [legsDefRed1, legsDefRed2],
        "#1e90ff": [legsDefBlue1],
        "#000000": [legsDefBlack1],
      },
    },
    {
      id: 11,
      name: "Защита голеностопа МП",
      description:
        "Защита голеностопа Maximum Protection(Муай Тай) спроектирована специально для этого вида единоборств. Оптимальная форма обеспечивает максимальную защиту по всей площади между ударной поверхностью и голенью и снижает риск получения травм ног во время тренировок и спаррингов. Дополнительную защиту обеспечивает специальное утолщение в районе большеберцовой кости и накладка на подъем стопы. Поверхность изготовлена из прочной высококачественной искусственной кожи, внутренний наполнитель пенополиуретан. Нескользящая подкладка позволяет бойцу чувствовать себя комфортно и уверенно работать в полную силу. Надежно фиксируется на ноге застежкой велкро.",
      price: 5500,
      colors: [{ name: "черно-белый", key: "#000000-#ffffff" }],
      sizes: ["S", "M", "L"],
      weights: [0.71, 0.71, 0.71],
      images: {
        "#000000-#ffffff": [legsMpBlack1],
      },
    },
    {
      id: 12,
      name: "Защита голеностопа МТ",
      description: "",
      price: 5500,
      colors: [
        { name: "красный", key: "#ff0000" },
        { name: "синий", key: "#1e90ff" },
        { name: "черный", key: "#000000" },
        // { name: "белый", key: "#ffffff" },
        // { name: "золотой", key: "#c89832" },
      ],
      sizes: ["S", "M", "L"],
      images: {
        "#ff0000": [legsMtRed1, legsMtRed2, legsMtRed3],
        "#1e90ff": [legsMtBlue1, legsMtBlue2, legsMtBlue3, legsMtBlue4],
        "#000000": [legsMtBlack1, legsMtBlack2, legsMtBlack3, legsMtBlack4],
        // "#ffffff": [legsMtWhite1, legsMtWhite2, legsMtWhite3],
        // "#c89832": [legsMtGold1, legsMtGold2, legsMtGold3, legsMtGold4],
      },
    },
  ],
};

export const productsSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
});
