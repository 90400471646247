export const url = "https://api.topfightschool.ru";
// export const url = "http://localhost:3000";
export const googleSheetsUrl =
  "https://script.google.com/macros/s/AKfycbxkxjNqAx1U_WmhwlLn0CKuQ4PA-PrnKK-31RVUgn71zNTyaMhzd__wi1CggSuCVcqr1g/exec";
export const googleSheetsShopUrl =
  "https://script.google.com/macros/s/AKfycbwtGzYJqXs0o-uKCG50pT9dvjDiDd8bYw5gVGa2NSzQ2CbJirckT_IcAtzkKNXSJ9-lsQ/exec";

export enum Routes {
  Home = "/",
  Locations = "/locations",
  Children = "/children",
  Adults = "/adults",
  Camps = "/camps",
  Competitions = "/competitions",
  Trainings = "/trainings",
  Shop = "/shop",
  Team = "/team",
  Contacts = "/contacts",
  Appointment = "/appointment",
  Gallery = "/gallery",
}

export enum AdminRoutes {
  Home = "/admin",
  Locations = "/admin/locations",
  Team = "/admin/team",
  Gallery = "/admin/gallery",
}

export enum WeekDays {
  Monday = "Понедельник",
  Tuesday = "Вторник",
  Wednesday = "Среда",
  Thursday = "Четверг",
  Friday = "Пятница",
  Saturday = "Суббота",
  Sunday = "Воскресенье",
}

export enum RoleTypes {
  Director = 'Руководитель',
  MainCoach = 'Главный тренер',
  Coach = 'Тренер',
  Sportsman = 'Спортсмен'
}
