import { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { IProduct } from "../../services/types/product";
import { useState } from "react";
import { addCartItem } from "../../services/slices/cart-slice";
import { Helmet } from "react-helmet";

const ProductPage = () => {
  const { id } = useParams() as { id: string };
  const product = useAppSelector(
    (store) =>
      store.products.products.find((item) => item.id === +id) as IProduct
  );
  const [colorKey, setColorKey] = useState(product.colors[0].key);
  const [imageIndex, setImageIndex] = useState(0);
  const [sizeIndex, setSizeIndex] = useState(0);
  const [currentQuantity, setCurrentQuantity] = useState(1);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const dispatch = useAppDispatch();

  const handleImageClick = (index: number) => {
    setImageIndex(index);
  };

  const handleColorClick = (key: string) => {
    setImageIndex(0);
    setColorKey(key);
  };

  const handleSizeChange = (evt: SelectChangeEvent) => {
    setSizeIndex(+evt.target.value);
  };

  const handleQuantityChange = (evt: SelectChangeEvent) => {
    setCurrentQuantity(+evt.target.value);
  };

  const handleAddProduct = () => {
    dispatch(
      addCartItem({
        id: product.id,
        name: product.name,
        color: product.colors.find((item) => item.key === colorKey) as {
          key: string;
          name: string;
        },
        size: product.sizes[sizeIndex],
        quantity: currentQuantity,
        weight: product.weights ? product.weights[sizeIndex] : undefined,
        price: Array.isArray(product.price)
          ? product.price[sizeIndex]
          : product.price,
      })
    );

    setIsAlertShown(true);

    setTimeout(() => setIsAlertShown(false), 3000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.description.split(".")[0]} />
        <meta name="keywords" content="" />
      </Helmet>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 4, md: 6, lg: 8 },
        }}
      >
        <Box sx={{ maxWidth: { md: "50%" } }}>
          <Box
            sx={{ maxWidth: "100%" }}
            component="img"
            src={product.images[colorKey][imageIndex]}
          />
          <List
            sx={{
              // maxWidth: "50%",
              py: 2,
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              gap: 2,
              overflow: "hidden",
            }}
          >
            {product.images[colorKey].map((src, index) => (
              <ListItem key={index} sx={{ p: 0, width: "fit-content" }}>
                <Box
                  component="img"
                  sx={{ height: "80px", cursor: "pointer" }}
                  src={src}
                  alt={`${product.name} ${index}`}
                  onClick={() => handleImageClick(index)}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box>
          <Typography variant="h1" mb={2} fontSize={24}>
            {product.name} ({product.sizes[sizeIndex]},{" "}
            {
              product.colors[
                product.colors.findIndex((item) => item.key === colorKey)
              ].name
            }
            )
          </Typography>
          <Typography variant="h1" mb={4}>
            {Array.isArray(product.price)
              ? product.price[sizeIndex]
              : product.price}
            {" "}₽
          </Typography>
          <Typography variant="h4" mb={2}>
            Цвет:
          </Typography>
          <Box sx={{ mb: 4, display: "flex", alignItems: "center", gap: 2 }}>
            {product.colors.map(({}, index) => {
              const key = product.colors[index].key;
              return (
                <Tooltip key={key} title={product.colors[index].name}>
                  <IconButton
                    key={index}
                    sx={{
                      width: 40,
                      height: 40,
                      p: 0,
                      borderRadius: "50%",
                    }}
                    onClick={() => handleColorClick(key)}
                  >
                    <Box
                      sx={{
                        width: key === colorKey ? 40 : 36,
                        height: key === colorKey ? 40 : 36,
                        background:
                          key.split("-").length === 2
                            ? `linear-gradient(to bottom right, ${
                                key.split("-")[0]
                              } 50%, ${key.split("-")[1]} 0)`
                            : key,
                      }}
                    ></Box>
                  </IconButton>
                </Tooltip>
              );
            })}
          </Box>
          <Box sx={{ mb: 4, display: "flex", gap: 2 }}>
            <FormControl sx={{ width: 100 }}>
              <InputLabel id="size">Размер</InputLabel>
              <Select
                labelId="size"
                label="Размер"
                value={String(sizeIndex)}
                onChange={handleSizeChange}
              >
                {product.sizes.map((size, index) => (
                  <MenuItem key={size} value={index}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 100 }}>
              <InputLabel id="quantity">Количество</InputLabel>
              <Select
                labelId="quantity"
                label="Количество"
                value={String(currentQuantity)}
                onChange={handleQuantityChange}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {product.weights && (
            <Typography mb={12}>
              Вес:{" "}
              <span style={{ fontWeight: 600 }}>
                {product.weights[sizeIndex]}
              </span>{" "}
              кг.
            </Typography>
          )}
          <Button
            sx={{ p: 4 }}
            color="tertiary"
            variant="outlined"
            onClick={handleAddProduct}
          >
            Добавить в корзину
          </Button>
        </Box>
      </Box>
      {product.description.length && (
        <Typography variant="h4" mb={2}>
          Описание
        </Typography>
      )}
      <Typography>{product.description}</Typography>
      <Alert
        sx={{
          position: "fixed",
          top: 80,
          right: 20,
          opacity: isAlertShown ? 1 : 0,
          transition: "opacity .3s ease-in",
        }}
        severity="error"
      >
        Товар добавлен в корзину
      </Alert>
    </Container>
  );
};

export default ProductPage;
